import React, { useEffect, useState } from 'react';

import axios from "axios";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faLock, faShareAlt, faExchangeAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons';


function IntegrationPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [errorContact, setErrorContact] = useState(null);
  const [step, setStep] = useState(1);

  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const imgUrls = ["/img/4d-emr.png", "/img/cobalt-logo.png", "/img/knowtex-logo.png"];
    const imgPromises = imgUrls.map(imgUrl => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgUrl;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(imgPromises)
      .then(() => setImagesLoaded(true))
      .catch(err => console.log("Failed to load images", err));
  }, []);

  if (!imagesLoaded) {
    return null; // or a loading spinner
  }

  const handleSubmit = async () => {
    setStep(3);

    // Give time to show the loading screen
    await new Promise(resolve => setTimeout(resolve, 2000));

    try {
      // Extract query parameters
      const params = new URLSearchParams(window.location.search);
      const knowtexClientId = params.get('knowtex-client-id');
      const callbackUrl = params.get('callback-url')
      const state = params.get('state')

      // Send query parameters to the server
      var axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
      const response = await axiosInstance.post(`/oauth/authenticate`, 
        { 
          email, password, knowtexClientId, callbackUrl, state
        }, 
        { 
          withCredentials: true 
        },
      );
      console.log("server response sucess: ", response);

      if (response.data.success) {
        setStep(4)
        // Give time to show the loading screen
        await new Promise(resolve => setTimeout(resolve, 5000));

        const callbackUrl = new URL(response.data['callback-url']);
        callbackUrl.searchParams.append('code', response.data.code);
        callbackUrl.searchParams.append('state', response.data.state);
        window.location.href = callbackUrl.href;
      }
    } catch (error) {
      console.error("server response error: ", error);
      switch (error.response.status) {
        case 400:
          setError("There was an error linking your account. Please go back and try again.");
          setErrorContact("4D")
          setStep(6);
          break;
        case 401:
          setError("The email or password you entered was not correct. Please go back and try again.");
          setErrorContact("knowtex")
          setStep(6);
          break;
        case 409:
          setError("There was an error linking your account. The email you entered has already been linked.");
          setErrorContact("4D")
          setStep(6);
          break;
        case 500:
          setError("There was a problem logging you in. Please go back and try again.");
          setErrorContact("4D")
          setStep(6);
          break;
        default:
          setError("There was a problem logging you in. Please go back and try again.");
          setErrorContact("4D")
          setStep(6);
      }
    }

  };

  const isFormValid = () => {
    return email && password;
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-400 bg-opacity-90">
      <div className="pl-7 pr-7 pt-4 pb-8 w-360 h-[85vh] bg-white rounded shadow-lg flex flex-col rounded-lg">
        {step === 1 && (
          <>
            <div className="flex justify-center space-x-4 mb-8 mt-8 items-center">
              <img src="/img/4d-emr.png" alt="4D EMR Logo" className="h-6" />
              <FontAwesomeIcon icon={faShareAlt} className="self-center text-gray-500" />
              <img src="/img/cobalt-logo.png" alt="Acme Logo" className="h-7 mb-2" />
            </div>
            <h1 className="mx-auto mb-12 text-center font-light">4D EMR uses <span className="font-semibold">Cobalt</span> to connect your Knowtex account</h1>
            <div className="w-10/12 mx-auto">
              <h2 className="mb-1">
                <FontAwesomeIcon icon={faExchangeAlt} className="mr-1" /> Connect
              </h2>
              <p className="mb-4 ml-6">Cobalt lets you connect data from other applications in seconds.</p>
              <h2 className="mb-1">
                <FontAwesomeIcon icon={faLock} className="mr-1" /> Secure
              </h2>
              <p className="mb-4 ml-6">Coablt never stores patient data.</p>
            </div>
            <div className="mt-auto">
              <hr className="border-gray-200 mb-4" />
              <p className="mb-6 text-center text-sm text-gray-600">By selecting "Continue" you agree to the <a href="https://www.usecobalt.com/end-user-privacy-policy" target="_blank" rel="noopener noreferrer" className="font-semibold underline text-gray-600">Cobalt End User Privacy Policy</a></p>
              <button onClick={() => setStep(2)} className="p-2 w-full bg-primary text-white rounded h-12 hover:bg-primary-dark transition-colors duration-200">
                Continue
              </button>
            </div>
          </>
        )}
  
        {step === 2 && (
          <>
            <div className="flex items-left">
              <button onClick={() => setStep(1)} className="text-center mb-8">
                <FontAwesomeIcon icon={faArrowLeft} className="self-center text-gray-500" />
              </button>
            </div>
            <div className="flex justify-center space-x-4 mb-8 mt-8 items-center">
              <img src="/img/knowtex-logo.png" alt="Knowtex Logo" className="h-12 mb-3" />
              <FontAwesomeIcon icon={faShareAlt} className="self-center text-gray-500" />
              <img src="/img/cobalt-logo.png" alt="Acme Logo" className="h-7" />
            </div>

            <div className="flex flex-col items-start mb-1">
              {/* <img src="/img/knowtex-logo.png" alt="Knowtex Logo" className="h-16" /> */}
              <h1 className="text-left font-normal">Enter your credentials</h1>
            </div>

            <p className="mb-4">By providing your Knowtex credentials to Cobalt, you're enabling Cobalt to synchronize data to 4D EMR on your behalf.</p>
            <div className="flex flex-col items-centers">
              <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="mb-2 p-2 border rounded w-full"
                />

              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="mb-2 p-2 border rounded w-full"
              />

              <button
                disabled={!isFormValid()}
                onClick={handleSubmit}
                className={`p-2 w-full bg-primary text-white rounded h-12 ${isFormValid() ? 'hover:bg-primary-dark transition-colors duration-200' : 'disabled:opacity-50'}`}
              >
                Submit
              </button>
            </div>
          </>
        )}
  
        {step === 3 && (
          <>
            <div className="flex justify-center mb-6">
              <img src="/img/cobalt-logo.png" alt="XYZ Logo" className="h-4" />
            </div> 
            <div className="mt-4 flex flex-col items-center justify-center h-full">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              <p className="mt-2 text-center">Authorizing...</p>
            </div>
          </>
        )}

        {step === 4 && (
          <>
            <div className="flex justify-center mb-6">
              <img src="/img/cobalt-logo.png" alt="XYZ Logo" className="h-4" />
            </div> 
            <div className="mt-4 flex flex-col items-center justify-center h-full">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              <p className="mt-2 text-center">Account successfully linked!</p>
              <p className="mt-2 text-center">Redirecting you back to 4D EMR...</p>
            </div>
          </>
        )}

        {step === 5 && (
          <>
            <div className="flex flex-col items-center justify-center h-2/3">
              <div className="flex justify-center space-x-4 mb-8 mt-8 items-center">
                <img src="/img/4d-emr.png" alt="Knowtex Logo" className="h-6" />
                  <FontAwesomeIcon icon={faLink} className="self-center text-gray-500" />
                <img src="/img/knowtex-logo.png" alt="Knowtex Logo" className="h-12 mb-3" />
              </div>
              <h1 className="mb-4">Integration successful!</h1>
              <div className="w-5/6 ml-7">
               <p>Your data will now be synchronized between 4D EMR and Knowtex</p>
              </div>
              <div className="mt-4">
                <a href="http://localhost:3001?redirect=true" className="text-blue-500 underline">Back to 4D EMR</a>
              </div>
            </div>
          </>
        )}

        {step === 6 && (
          <>
            <div className="flex items-left">
              <button onClick={() => setStep(2)} className="text-center mb-8">
                <FontAwesomeIcon icon={faArrowLeft} className="self-center text-gray-500" />
              </button>
            </div>
            <div className="flex flex-col items-center justify-center h-2/3">
              <h1 className="mb-4">A problem occured.</h1>
              <div className="w-5/6 ml-7">
                <p>{error}</p>
              </div>
              <br />
              <div className="w-5/6 ml-7">
                <p>If you need assistance please contact {errorContact === "knowtex" ? process.env.REACT_APP_KNOWTEX_SUPPORT_EMAIL : process.env.REACT_APP_EMR_SUPPORT_EMAIL}.</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );  
}

export default IntegrationPage;